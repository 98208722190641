<template>
  <div class="fh-loader">
    <div class="loader"></div>
    <span class="loading-text" v-if="text">{{ text }}</span>
    <span class="loading-text" v-if="subtitle">{{ subtitle }}</span>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    text: String,
    subtitle: String,
  },
};
</script>
