import { ga4Event } from './EventService';

export const eventPaymentAttempted = (result, provider, FHCode) => {
  ga4Event('payment_attempt_submitted', {
    payment_result: result,
    payment_provider: provider,
    FH_code: FHCode
  });
};

export const eventPaymentClicked = bookingAmount => {
  ga4Event('payment_requested', {
    booking_amount: bookingAmount
  });
};

